import {Avatar, Dialog} from "@mui/material"
import React, {useContext} from "react"
import ReactDOM from "react-dom"
import LogIn from "../LogIn/LogIn"
import AuthContext from "../../../context/auth-context"

const ChefControl = () => {
    const authContext = useContext(AuthContext)

    if (authContext.loggedUser === null) {
        return ReactDOM.createPortal(
            (
                <Dialog open={true}>
                    <LogIn/>
                </Dialog>
            ),
            document.getElementById('primary')
        )
    }

    // use account menu https://mui.com/components/menus/#account-menu

    return (
        <Avatar
            className="profile-avatar"
            alt={authContext.loggedUser.name}
            src={authContext.loggedUser.picture}
            sx={{ width: 50, height: 50 }}
        />
    )
}

export default ChefControl