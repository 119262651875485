import {Box, MenuItem, Select, SelectChangeEvent} from "@mui/material";

interface MealSortingProps {
    selected: string,
    onSortingChange: (sorting: string) => void
}

const MealSorting = (props: MealSortingProps) => {
    const selected = props.selected !== '' ? props.selected : 'general';

    const onChangeHandler = (event: SelectChangeEvent) => {
        props.onSortingChange(event.target.value);
    };

    return (
        <Box className="sorting">
            <Select label="Řazení" defaultValue="general" value={selected} onChange={onChangeHandler} className="sorting__select">
                <MenuItem value="general">Doporučené</MenuItem>
                <MenuItem value="popularity" disabled={true}>Oblíbené</MenuItem>
                <MenuItem value="healthiness" disabled={true}>Zdravé</MenuItem>
            </Select>
        </Box>
    );
};

export default MealSorting;