import {IconButton, Stack, Toolbar} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu';
import ChefControl from "../../Chef/Control/ChefControl"
import "./Header.css";
import {Link} from "react-router-dom";

const Header = () => {
    return (
        <Toolbar className="header-toolbar" sx={{borderBottom: 1, borderColor: 'divider'}}>
            <Stack direction="row">
                <Link to="/">
                    <IconButton className="burger-menu">
                        <MenuIcon/>
                    </IconButton>
                </Link>
                <div id="logo">
                    <Link to="/">
                        <img src="/make-my-menu.png" alt="MakeMyMenu"/>
                    </Link>
                </div>
                <ChefControl/>
            </Stack>
        </Toolbar>
    )
}

export default Header