import React, {useContext} from "react";
import MealsContext from "../../context/meals-context";
import MealItemDetail from "../../components/Meal/MealItemDetail";
import {useParams} from "react-router-dom";

const MealDetail = () => {
  const params = useParams();
  const { mealId } = params;

  const mealsContext = useContext(MealsContext);
  const meal = mealId ? mealsContext.findMeal(mealId) : null;

  if (meal === null) {
    return (
        <p>Meal not found</p>
    )
  }

  return (
      <MealItemDetail meal={meal}/>
  )
}

export default MealDetail;