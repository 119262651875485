import Meal from "../../models/meal";
import React, {useContext} from "react";
import MealsContext from "../../context/meals-context";
import EditMeal from "../../components/Meal/Edit/EditMeal";
import {useParams} from "react-router-dom";

const MealDetailEdit = () => {
  const mealsContext = useContext(MealsContext);
  const params = useParams();
  const { mealId } = params;

  const refreshRecipes = (meal: Meal) => {
      mealsContext.refresh();
  }

  const meal = mealId ? mealsContext.findMeal(mealId) : null;

  if (meal === null) {
    return (
        <p>Meal not found</p>
    )
  }

  return (
    <EditMeal onMealSave={refreshRecipes} mealToEdit={meal}/>
  )
  
}

export default MealDetailEdit;