import React, {useState} from 'react'
import MealItem from "./MealItem"
import MealSorting from "./Sorting/MealSorting"
import Meal from "../../models/meal";
import {Grid} from "@mui/material";

interface MealListProps {
    meals: Meal[]
}

const MealList = (props: MealListProps) => {
    const [sortingValue, setSortingValue] = useState('')

    const sortingChangeHandler = (sorting: any) => {
        setSortingValue(sorting)
    }
    return (
        <Grid container className="meal-list" spacing={{xs: 2, md: 4}}>
            <Grid item xs={12}>
                <MealSorting selected={sortingValue} onSortingChange={sortingChangeHandler}/>
            </Grid>
            {props.meals.map(
                meal => <Grid key={meal.id} item xs={12} md={6}><MealItem meal={meal}/></Grid>
            )}
        </Grid>
    )
}

export default MealList