import React, {useContext, useState} from 'react'
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    IconButton,
    Stack
} from "@mui/material"
import './Meal.css'
import './MealItemDetail.css'
import Meal from "../../models/meal";
import DateComponent from "../UI/General/DateComponent";
import {Delete, Edit} from "@mui/icons-material";
import {Link, useNavigate} from "react-router-dom";
import {callApi} from "../../api/call-api";
import MealsContext from "../../context/meals-context";

interface MealProps {
    meal: Meal
}

const MealItemDetail = (props: MealProps) => {
    const mealEditLink = `/meal-detail/${props.meal.id}/edit`;
    const [deleteDialog, setDeleteDialog] = useState(false)
    const mealsContext = useContext(MealsContext);
    const navigate = useNavigate();

    const handleClose = () => {
        setDeleteDialog(false);
    };

    const clickDelete = () => {
        setDeleteDialog(true)
    }

    const handleDeleteMeal = async () => {
        await callApi(`/meal/${props.meal.id}`, 'DELETE');
        mealsContext.refresh();
        navigate('/your-meals');
    }

    if (deleteDialog) {
        return (<Dialog
            open={deleteDialog}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Opravdu chcete smazat recept?"}
            </DialogTitle>
            <DialogActions>
                <Button onClick={handleDeleteMeal}>Ano</Button>
                <Button onClick={handleClose} autoFocus>Ne</Button>
            </DialogActions>
        </Dialog>)
    }

    return (
        <Box className="meal-detail">
            <Stack direction="row" justifyContent="flex-end" alignItems="flex-start" spacing={0}
                   className="meal-detail__controls">
                <Link to={mealEditLink} className="meal__edit-icon">
                    <IconButton color="primary" aria-label="upload picture" component="label">
                        <Edit/>
                    </IconButton>
                </Link>
                <IconButton onClick={clickDelete} color="primary" aria-label="upload picture" component="label">
                    <Delete/>
                </IconButton>
            </Stack>
            <Stack direction="column" spacing={2}>
                {props.meal.image !== '' &&
                    <img alt={props.meal.name} className="meal-item__icon" src={props.meal.image}/>}
                <div className="meal__description">
                    <h2 className="meal__description__name">{props.meal.name}</h2>
                    <div className="recipe-item__description__tags"></div>
                    <div dangerouslySetInnerHTML={{__html: props.meal.description}}/>
                </div>
                {props.meal.lastCooking !== null && <DateComponent className="meal" date={props.meal.lastCooking}/>}
            </Stack>
        </Box>
    )
}

export default MealItemDetail