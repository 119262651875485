import {Box, Button, Stack} from "@mui/material";
import {Link} from "react-router-dom";

const Home = () => {
  return (
    <Box sx={{width: '100%'}}>
      <Stack spacing={4} direction="column">
        <Button component={Link} to={'/your-menu'} variant="contained">Vybrat z menu</Button>
        <Button component={Link} to={'/your-meals'} variant="contained">Tvoje recepty</Button>
        <Button component={Link} to={'/new-meal'} variant="contained">Přidat recept</Button>
      </Stack>
    </Box>
  )
}

export default Home