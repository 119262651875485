import MenuOrder from "./MenuShuffleOrder";
import MenuShuffleConf from "./MenuShuffleConf";

export const menuShuffleConfFactory = (order: MenuOrder, customOrderId: string | null = null): MenuShuffleConf => {
    let conf = {
        favoriteWeight: 1,
        healthyWeight: 1,
        cookedDateWeight: 1,
    };

    switch (order) {
        case MenuOrder.Favorite:
            conf.favoriteWeight = 5;
            break;
        case MenuOrder.Healthy:
            conf.healthyWeight = 5;
            break;
        case MenuOrder.CookedDate:
            conf.cookedDateWeight = 5;
            break;
        case MenuOrder.Custom:
            conf.favoriteWeight = 5;
            break;
        default:
            conf = {
                favoriteWeight: 2,
                healthyWeight: 2,
                cookedDateWeight: 4,
            };
    }

    return conf;
}