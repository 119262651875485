import {callApiWithData} from "../../../api/call-api"
import Meal from "../../../models/meal";
import MealForm from "../MealForm";
import {callRecipeFromImageApi} from "../../../api/assistant-api";

interface AddMealProps {
    onMealSave: (meal: Meal) => void
}


const AddMeal = (props: AddMealProps) => {
    const createMeal = async (name: string, image: string, description: string): Promise<Meal> => {
        const response = await callApiWithData('/meal', {
                name,
                image,
                description
            }, 'POST'
        )

        return ({
            id: response.meal.id,
            name: response.meal.name,
            image: response.meal.image,
            description: response.meal.description
        } as Meal)
    }

    const initialDescription = '\n' +
        '                            <p>\n' +
        '                                <strong>Ingredience:</strong>\n' +
        '                            </p>\n' +
        '                            <p>\n' +
        '                                <ul>\n' +
        '                                    <li>první ingredience</li>\n' +
        '                                    <li>druhá ingredience</li>\n' +
        '                                </ul>\n' +
        '                            </p>\n' +
        '                            <p>Popis</p>\n' +
        '\n' +
        '                        ';

    return (
        <MealForm
            onMealSave={props.onMealSave}
            submitMeal={createMeal}
            initialName='Název'
            initialDescription={initialDescription}
            initialImage=''
            getRecipeFromImage={callRecipeFromImageApi}
        ></MealForm>

    )
}

export default AddMeal