import Meal from "../../models/meal";
import MenuShuffleConf from "./MenuShuffle/MenuShuffleConf";

export const shuffleMenu = (conf: MenuShuffleConf, meals: Array<Meal>) => {
    const today = new Date();
    
    return meals.sort((a: Meal, b: Meal) => {
        let aScore = 0;
        let bScore = 0;

        // Sort by cooking count (lowest priority)
        if (a.cookingCount > b.cookingCount) {
            aScore += 1;
        } else if (a.cookingCount < b.cookingCount) {
            bScore += 1;
        }

        if (a.averageCookingDate === null && b.averageCookingDate === null) {
            return bScore - aScore;
        }

        if (a.averageCookingDate === null) {
            aScore += 3;
            return bScore - aScore;
        }

        if (b.averageCookingDate === null) {
            bScore += 3;
            return bScore - aScore;
        }

        // Sort by last cooking date (highest priority)
        if (a.lastCooking > b.lastCooking) {
            bScore += 3;
            if (a.lastCooking.getTime() - b.lastCooking.getTime() > 604800000) {
                bScore += 2;
            }
        } else if (a.lastCooking < b.lastCooking) {
            aScore += 3;
            if (b.lastCooking.getTime() - a.lastCooking.getTime() > 604800000) {
                aScore += 2;
            }
        }

        if (a.averageCookingDate > b.averageCookingDate) {
            bScore += 2;
            if (a.averageCookingDate.getTime() - b.averageCookingDate.getTime() > 604800000) {
                bScore += 2;
            }
        } else if (a.averageCookingDate < b.averageCookingDate) {
            aScore += 2;
            if (b.averageCookingDate.getTime() - a.averageCookingDate.getTime() > 604800000) {
                aScore += 2;
            }
        }

        // Adjust scores if a meal has been cooked in the last 7 days
        if (a.lastCooking.getTime() > today.getTime() - 604800000) {
            aScore -= 2;
        }
        if (b.lastCooking.getTime() > today.getTime() - 604800000) {
            bScore -= 2;
        }

        return bScore - aScore;
    });
}