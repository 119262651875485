import ReactDOM from "react-dom";
import {Alert, Snackbar} from "@mui/material";

type NotificationType = 'success' | 'error' | 'info' | 'warning'

interface NotifyProps {
    type: NotificationType,
    message: string
}

export const showNotification = ({ type, message }: NotifyProps) => {
    const container = document.createElement('div');
    document.body.appendChild(container);

    ReactDOM.render(
        (
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal:'right' }}
                open={true}
                sx={{position: 'fixed', zIndex: 1500}}
            >
                <Alert severity={type} sx={{ width: '100%' }}>
                {message}
                </Alert>
            </Snackbar>
        ),
        container
    );

    setTimeout(() => {
        document.body.removeChild(container);
    }, 5000);
};
