import React from 'react'
import './App.css'
import {Route, Routes} from 'react-router-dom'
import Home from "./pages/Home"
import {Box, createTheme, Grid, ThemeProvider} from "@mui/material";
import Header from "./components/UI/Header/Header";
import YourMeals from "./pages/Meal/YourMeals";
import YourMenu from "./pages/YourMenu";
import NewMeal from "./pages/Meal/NewMeal";
import MealDetail from "./pages/Meal/MealDetail";
import MealDetailEdit from "./pages/Meal/MealDetailEdit";

const theme = createTheme({
  palette: {
    primary: {
      main: '#86de69',
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Header/>
        </Grid>
        <Grid item xs={12}>
          <Box padding={theme.spacing(1,2)}>
            <Routes>
              <Route index element={<Home/>}/>
              <Route path="/your-menu" element={<YourMenu/>}/>
              <Route path="/your-meals" element={<YourMeals/>}/>
              <Route path="/new-meal" element={<NewMeal/>}/>
              <Route path="/meal-detail/:mealId" element={<MealDetail/>}/>
              <Route path="/meal-detail/:mealId/edit" element={<MealDetailEdit/>}/>
            </Routes>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  )
}

export default App
