import React, {useContext, useState} from 'react'
import {Card, Dialog, Grid, IconButton, Stack} from "@mui/material"
import './Meal.css'
import ReactDOM from "react-dom"
import CookMeal from "./Cook/CookMeal"
import Meal from "../../models/meal";
import DateComponent from "../UI/General/DateComponent";
import {Link} from "react-router-dom";
import {Restaurant} from "@mui/icons-material";
import MealsContext from "../../context/meals-context";

interface MealProps {
    meal: Meal
}

const MealItem = (props: MealProps) => {
    const meal = props.meal;
    const [cookDialog, setCookDialog] = useState(false)
    const mealImage = meal.image !== null && meal.image !== '' ? meal.image : "/images/blank-meal.jpg"
    const mealsContext = useContext(MealsContext);

    const onCookDateSet = (date: Date) => {
        mealsContext.refresh();
        setCookDialog(false);
    }

    const handleClose = () => {
        setCookDialog(false);
    };

    if (cookDialog) {
        return ReactDOM.createPortal(
            (
                <Dialog open={true}>
                    <CookMeal
                        mealName={meal.name}
                        mealId={meal.id}
                        onCookDateSet={onCookDateSet}
                        onClose={handleClose}
                    />
                </Dialog>
            ),
            document.getElementById('primary') as HTMLElement
        )
    }

    const clickRecipe = () => {
        setCookDialog(true)
    }

    return (
        <Card>
            <Grid container spacing={0}>
                <Grid item xs={11}>
                    <Link to={`/meal-detail/${meal.id}`}>
                        <Stack direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
                            {<img alt={meal.name} className="meal-item__icon" src={mealImage}/>}
                            <Stack direction="column" spacing="2" alignItems="flex-start">
                                <div className="meal__name">{meal.name}</div>
                                {meal.lastCooking !== null &&
                                    <DateComponent className="meal" date={meal.lastCooking} title={"Naposledy:"}/>}
                                {meal.lastCooking === null && <div className="meal__date">Ještě neuvařeno</div>}
                            </Stack>
                        </Stack>
                    </Link>
                </Grid>
                <Grid item xs={1} paddingRight={2}>
                    <Stack alignItems={"center"} justifyContent={"center"} height="100%">
                        <IconButton onClick={clickRecipe} color="primary" aria-label="upload picture" component="label">
                            <Restaurant/>
                        </IconButton>
                    </Stack>
                </Grid>
            </Grid>
        </Card>
    )
}

export default MealItem