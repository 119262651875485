import ReactDOM from 'react-dom'
import {BrowserRouter} from "react-router-dom"
import './index.css'
import App from './App'
import {AuthContextProvider} from "./context/auth-context"
import {MealsContextProvider} from "./context/meals-context"

ReactDOM.render(
  <BrowserRouter>
    <MealsContextProvider>
      <AuthContextProvider>
        <App/>
      </AuthContextProvider>
    </MealsContextProvider>
  </BrowserRouter>
  , document.getElementById('root')
)
