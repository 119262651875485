import Meal from "./meal";

export const initialMealsState = {
  meals: [] as Array<Meal>,
  addMeal: (meal: Meal) => {},
  refresh: () => {},
  findMeal: (id: string): Meal | null => { return null },
};

export type MealsState = typeof initialMealsState;
