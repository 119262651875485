import FacebookLogin from 'react-facebook-login'
import {Alert, Box, Grid, Typography} from "@mui/material"
import {useContext, useState} from "react"
import AuthContext from "../../../context/auth-context"
import {callApiWithData} from "../../../api/call-api"

const LogIn = () => {
    const [loginState, setLoginState] = useState(true)
    const authContext = useContext(AuthContext)
    const fbAppId = window.env.FB_APP_ID

    const responseFacebook = async (fbResponse) => {
        if (fbResponse.status === 'unknown') {
            setLoginState(false)
            return
        }

        const fbAccessToken = fbResponse?.accessToken;
        const userId = fbResponse.id;

        const response = await callApiWithData(
            '/facebook-auth',
            {
                accessToken: fbAccessToken,
                userId,
            },
        )

        const accessToken = response.accessToken
        authContext.onLogin(accessToken);
    }

    return (
        <Grid container spacing={6}>
            <Grid item xs={12}>
                <Box sx={{borderBottom: 1, borderColor: 'divider', alignItems: 'center'}}>
                    <Typography variant="h2" align="center">Přihlášení</Typography>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Typography align="center">
                    <FacebookLogin
                        appId={fbAppId}
                        fields="name,email,picture,gender"
                        disableMobileRedirect={true}
                        callback={responseFacebook}
                    />
                </Typography>
            </Grid>
            <Grid item xs={12}>
                {
                    !loginState &&
                    (<Alert severity="error">Přihlášení se nepodařilo. Prosím, zkuste to znovu.</Alert>)
                }
            </Grid>
        </Grid>

    )
}

export default LogIn