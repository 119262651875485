import {Button, Grid, TextField, Typography} from "@mui/material"
import React, {useRef} from "react"
import {callApiWithData} from "../../../api/call-api"

interface CookMealProps {
    mealId: string
    mealName: string
    onCookDateSet: (date: Date) => void
    onClose: () => void
}

const CookMeal = (props: CookMealProps) => {
    const dateRef = useRef<HTMLInputElement>()

    const submitHandler = async (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault()
        const date = dateRef.current?.value

        const response = await callApiWithData(
            '/cook-meal',
            {
                mealId: props.mealId,
                cookedDate: date
            }
        )

        const lastCookingDate = new Date(response.meal.lastCooking * 1000)
        props.onCookDateSet(lastCookingDate)
    }

    return (
        <Grid container spacing={2} alignItems={"center"} padding={2}>
            {/*<Grid item xs={12}>*/}
            {/*    <Box sx={{borderBottom: 1, borderColor: 'divider', alignItems: 'center'}}>*/}
            {/*        <Typography variant="h4" align="center">Kd:</Typography>*/}
            {/*    </Box>*/}
            {/*</Grid>*/}
            <Grid item xs={12} textAlign={"center"}>
                <Typography variant="h5" align="center">{props.mealName}</Typography>
                <p>Jsme naposledy měli</p>
            </Grid>
            <Grid item xs={12} textAlign={"center"}>
                <TextField
                    inputRef={dateRef}
                    id="date"
                    type="date"
                    sx={{ width: 220 }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    defaultValue={new Date().toISOString().slice(0, 10)}
                    // inputProps={{max: new Date().toISOString().slice(0, 10)}}
                />
            </Grid>
            <Grid item xs={6} textAlign={"center"}>
                <Button variant="contained" onClick={submitHandler}>Uložit</Button>
            </Grid>
            <Grid item xs={6} textAlign={"center"}>
                <Button variant="contained" onClick={props.onClose}>Zrušit</Button>
            </Grid>
        </Grid>
    )
}

export default CookMeal