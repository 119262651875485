import React, {useContext} from "react";
import {Grid} from "@mui/material";
import MealList from "../Meal/MealList";
import MealsContext from "../../context/meals-context";
import {shuffleMenu} from "./shuffle-menu";
import {menuShuffleConfFactory} from "./MenuShuffle/menu-shuffle-conf-factory";
import MenuOrder from "./MenuShuffle/MenuShuffleOrder";

const MenuMaker = () => {
    const mealsContext = useContext(MealsContext);

    const conf = menuShuffleConfFactory(MenuOrder.Default)
    const sortedMeals = shuffleMenu(conf, mealsContext.meals)

    return (
        <Grid container>
            <Grid item xs={12}>
                <h2>Vaše menu</h2>
            </Grid>
            <Grid item xs={12}>
                <MealList meals={sortedMeals}/>
            </Grid>
        </Grid>
    )
}

export default MenuMaker