import React, {useEffect, useState} from 'react'
import jwtDecode from "jwt-decode"
import Cookies from "js-cookie"

const AuthContext = React.createContext({
    loggedUser: null,
    onLogin: (accessToken) => {},
    onLogout: () => {},
})

export const AuthContextProvider = (props) => {
    const [loggedUser, setLoggedUser] = useState(null)

    useEffect(() => {
        const accessToken = Cookies.get('accessToken');
        if (accessToken !== undefined){
            onLogin(accessToken)
        }
    }, [])


    const onLogin = (accessToken) => {

        Cookies.set('accessToken', accessToken, {
            expires: 7
        })
        const decodedToken = jwtDecode(accessToken)
        setLoggedUser({
            name: decodedToken.name,
            email: decodedToken.email,
            picture: decodedToken.picture,
        })
    }

    const onLogout = () => {
        setLoggedUser(null)
        Cookies.remove('accessToken')
    }

    return (
        <AuthContext.Provider
            value={{
                loggedUser: loggedUser,
                onLogin,
                onLogout,
            }}
        >
            {props.children}
        </AuthContext.Provider>
    )

}

export default AuthContext