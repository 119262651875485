interface DateComponentProps {
    className: string | null,
    date: Date | number,
    title?: string,
}

function DateComponent(props: DateComponentProps) {
    const className = props.className ? `${props.className}__date component-date` : 'component-date'
    const dateTime: Date = typeof props.date === 'number' ? new Date(props.date * 1000) : props.date;

    return (
        <div className={className}>
            {props.title && <span className={className + '__title'}>{props.title}</span>}
            <span
                className={className + '__day'}>{dateTime.getDate()}. {dateTime.getMonth() + 1}. {dateTime.getFullYear()}</span>
            {/*<div className={className + '__year'}>{dateTime.getFullYear()}</div>*/}
        </div>
    )
}

export default DateComponent