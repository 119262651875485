import React, {useEffect, useState} from 'react'
import {initialMealsState, MealsState} from "../models/meals-state";
import {callApi} from "../api/call-api";
import Meal from "../models/meal";

export const MealsContext = React.createContext<MealsState>(initialMealsState)

interface MealsContextProviderProps {
  children: JSX.Element;
}

export const MealsContextProvider = (props: MealsContextProviderProps) => {
  const [meals, setMeals] = useState<Meal[]>([])

  const refresh = async () => {
    const initialMeals = await callApi('/chef-meals');

    const meals = initialMeals.meals.map((meal: any) => {
      return ({
        id: meal.id,
        name: meal.name,
        image: meal.image,
        description: meal.description,
        lastCooking: meal.lastCooking ? new Date(meal.lastCooking*1000) : null,
        averageCookingDate: meal.averageCookingDate ? new Date(meal.averageCookingDate*1000) : null,
        cookingCount: meal.cookingCount,
      } as Meal)
    });

    setMeals(meals)
  }

  const addMeal = (meal: Meal) => {
    setMeals((prevState: Meal[]) => {
      return [...prevState, meal];
    })
  }

  const findMeal = (id: string): Meal | null => {
    return meals.find(meal => meal.id === id) ?? null;
  }

  useEffect(() => {
    refresh()
  }, []);

  return (
    <MealsContext.Provider
      value={{
        meals: meals,
        addMeal,
        refresh,
        findMeal,
      }}
    >
      {props.children}
    </MealsContext.Provider>
  )
}

export default MealsContext