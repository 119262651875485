import {ChangeEvent, FormEvent, useRef, useState} from "react"
import InfoIcon from '@mui/icons-material/Info';
import {styled} from '@mui/material/styles'
import {Box, Grid, InputLabel, TextField, Button, Stack, Alert, Typography} from "@mui/material"
import {Editor} from "@tinymce/tinymce-react"
import './MealForm.css'
import Meal from "../../models/meal";
import {Editor as TinyMCEEditor} from "tinymce";
import {showNotification} from "../UI/General/showNotification";

interface MealFormProps {
    onMealSave: (meal: Meal) => void
    submitMeal: (name: string, image: string, description: string) => Promise<Meal>
    getRecipeFromImage: (image: string) => Promise<string>
    initialName?: string
    initialDescription?: string
    initialImage?: string
}

const Input = styled('input')({
    display: 'none',
})

const MealForm = (props: MealFormProps) => {
    const refName = useRef<HTMLInputElement>()
    const refDescription = useRef<TinyMCEEditor>()
    const [image, setImage] = useState(props.initialImage || '')
    const [successfullySaved, setSuccessfullySaved] = useState(false);


    const submitHandler = async (event: FormEvent) => {
        event.preventDefault()
        if (refName === undefined) {
            return;
        }

        const name = refName.current!.value;
        const description = refDescription.current!.getContent()

        const meal = await props.submitMeal(name, image, description)

        props.onMealSave(meal)
        setSuccessfullySaved(true)
    }

    const saveFileAsBase64 = (file: File, callback: (result: string | ArrayBuffer | null) => void) => {
        let reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = function () {
            // TODO: use reducer to
            callback(reader.result);
        }
        reader.onerror = function (error) {
            console.log('Error: ', error)
        }
    }

    const recipeImageChanged = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files === null) {
            return;
        }

        saveFileAsBase64(event.target.files[0], (result) => setImage(result!.toString()))
    }

    const recipeDescriptionImageChanged = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files === null) {
            return;
        }

        saveFileAsBase64(
            event.target.files[0],
            async (result) => {
                try {
                    const description = await props.getRecipeFromImage(result!.toString())
                    refDescription.current!.setContent(description)
                } catch (e) {
                    showNotification({
                        type: 'error',
                        message: 'Nepodařilo se získat popis receptu z obrázku'
                    })
                }
            }
        )
    }

    return (
        <Box className="meal-form" component="form" onSubmit={submitHandler}>
            <Grid container spacing={2}>
                {successfullySaved && <Grid item xs={12}><Alert severity="success">Recept byl uložen</Alert></Grid>}
                <Grid item xs={12}>
                    <TextField
                        required
                        id="name"
                        label="Název"
                        className="meal-form__name"
                        defaultValue={props.initialName || ''}
                        inputRef={refName}
                    />
                </Grid>

                <Grid item xs={12}>
                    <label htmlFor="image">
                        <Stack direction="row" spacing={0}>
                            <Input accept="image/*" id="image" type="file" onChange={recipeImageChanged}/>
                            <Button variant="contained" component="span">
                                Nahrát obrázek
                            </Button>
                            {image !== '' && <img className="meal-form__image" src={image} alt="Obrázek receptu"/>}
                        </Stack>
                    </label>
                </Grid>

                <Grid item xs={12}>
                    <label htmlFor="recipeFromImage">
                        <Stack direction="row" spacing={0}>
                            <Input accept="image/*" id="recipeFromImage" type="file" onChange={recipeDescriptionImageChanged}/>
                            <Button variant="contained" component="span">
                                Vygenerovat popis z fotky receptu
                            </Button>
                        </Stack>
                    </label>
                    <Typography variant="caption" className="meal-form__recipe-from-image__tooltip">
                        <InfoIcon fontSize={"small"} className="meal-form__recipe-from-image__tooltip__icon" />Tato akce může trvat delší dobu.
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <InputLabel>Popis (recept)</InputLabel>
                    {/* @ts-ignore*/}
                    <Editor
                        apiKey="go9oqy69ulqf0t28vzu22mrk4gtvtocxlis4tw5d7nu11jdx"
                        onInit={(evt, editor) => refDescription.current = editor}
                        initialValue={props.initialDescription || ''}
                        init={{
                            height: 500,
                            menubar: false,
                            plugins: [
                                'advlist autolink lists link image charmap print preview anchor',
                                'searchreplace visualblocks code fullscreen',
                                'insertdatetime media table paste code help wordcount'
                            ],
                            toolbar: 'undo redo | bold italic | bullist numlist outdent indent | removeformat',
                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; }'
                        }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Button variant="contained" onClick={submitHandler}>Uložit recept</Button>
                </Grid>
            </Grid>
        </Box>

    )
}

export default MealForm