import {callApiWithData} from "../../../api/call-api"
import Meal from "../../../models/meal";
import MealForm from "../MealForm";
import {callRecipeFromImageApi} from "../../../api/assistant-api";

interface EditMealProps {
    mealToEdit: Meal
    onMealSave: (meal: Meal) => void
}


const EditMeal = (props: EditMealProps) => {
    const updateMeal = async (name: string, image: string, description: string): Promise<Meal> => {
        const response = await callApiWithData(`/meal/${props.mealToEdit.id}`, {
                name,
                image,
                description
            }, 'PUT'
        )

        return ({
            id: response.meal.id,
            name: response.meal.name,
            image: response.meal.image,
            description: response.meal.description
        } as Meal)
    }

    return (
        <MealForm
            onMealSave={props.onMealSave}
            submitMeal={updateMeal}
            initialName={props.mealToEdit.name}
            initialDescription={props.mealToEdit.description}
            initialImage={props.mealToEdit.image}
            getRecipeFromImage={callRecipeFromImageApi}
        ></MealForm>

    )
}

export default EditMeal