import Cookies from "js-cookie"

export const callApiWithData = async (url, data, method = 'POST', auth = true) => {
    const fullUrl = `${window.env.API_URL}${url}`

    let headers = new Headers()
    headers.append('Content-Type', 'application/json')

    if (auth === true) {
        const token = Cookies.get('accessToken')
        headers.append('Authorization', `Bearer ${token}`)
    }

    const response = await fetch(fullUrl,
        {
            method,
            headers,
            body: JSON.stringify(data)
        }
    )

    if (response.ok === false) {
        const body = await response.json()
        throw new Error(body.errorMessage)
    }

    return response.json()
}

export const callApi = async (url, method = 'GET', auth = true) => {
    const fullUrl = `${window.env.API_URL}${url}`
    let headers = new Headers()

    if (auth === true) {
        const token = Cookies.get('accessToken')
        headers.append('Authorization', `Bearer ${token}`)
    }

    const response = await fetch(fullUrl,
        {
            method,
            headers,
        }
    )

    if (response.ok === false) {
        const body = await response.json()
        throw new Error(body.errorMessage)
    }

    return response.json()
}
