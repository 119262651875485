import AddMeal from "../../components/Meal/Add/AddMeal";
import Meal from "../../models/meal";
import {useContext} from "react";
import MealsContext from "../../context/meals-context";

const NewMeal = () => {
  const mealsContext = useContext(MealsContext);

  const newRecipe = (meal: Meal) => {
      mealsContext.addMeal(meal);
  }

  return (
    <AddMeal onMealSave={newRecipe}/>
  )
}

export default NewMeal;