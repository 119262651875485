import React, {useContext} from "react";
import MealsContext from "../../context/meals-context";
import MealList from "../../components/Meal/MealList";

const YourMeals = () => {
  const mealsContext = useContext(MealsContext);

  return (
      <MealList meals={mealsContext.meals}/>
  )
}

export default YourMeals;